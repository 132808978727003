var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pay_password"},[_c('van-nav-bar',{class:!_vm.$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color',attrs:{"title":"Đổi mật khẩu"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{class:!_vm.$isLinkGame ? 'yellow-color' : 'white-color'},[_vm._v("Đổi mật khẩu")])]},proxy:true}])},[_c('i',{staticClass:"iconfont icon-jiantou1",attrs:{"slot":"left","color":!_vm.$isLinkGame ? '#ffd400' : '#fff'},on:{"click":function($event){return _vm.$router.back()}},slot:"left"})]),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"type":"password","name":"password","label":"Mật khẩu cũ","placeholder":"Vui lòng nhập ít nhất 6 chữ số.","minlength":"6","rules":[
        {
          required: true,
          message: 'Vui lòng nhập ít nhất 6 chữ số.',
        } ]},model:{value:(_vm.form.oldpass),callback:function ($$v) {_vm.$set(_vm.form, "oldpass", $$v)},expression:"form.oldpass"}}),_c('van-field',{attrs:{"type":"password","name":"password","label":"Mật khẩu mới","placeholder":"Vui lòng nhập ít nhất 6 chữ số.","minlength":"6","rules":[
        {
          required: true,
          message: 'Vui lòng nhập 6 chữ số làm mật khẩu đăng nhập của bạn.',
        } ]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{class:{ 'yellow-color': !_vm.$isLinkGame, 'black-bc': !_vm.$isLinkGame },attrs:{"block":"","type":"danger","native-type":"submit"}},[_vm._v("Gửi Thông Tin")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }