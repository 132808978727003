<template>
  <div class="pay_password">
    <!-- 导航栏 修改支付密码-->
    <van-nav-bar
      title="Đổi mật khẩu"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i
        slot="left"
        class="iconfont icon-jiantou1"
        @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"
      ></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
          >Đổi mật khẩu</span
        >
      </template>
    </van-nav-bar>

    <van-form @submit="onSubmit">
      <van-field
        v-model="form.oldpass"
        type="password"
        name="password"
        label="Mật khẩu cũ"
        placeholder="Vui lòng nhập ít nhất 6 chữ số."
        minlength="6"
        :rules="[
          {
            required: true,
            message: 'Vui lòng nhập ít nhất 6 chữ số.',
          },
        ]"
      />

      <van-field
        v-model="form.password"
        type="password"
        name="password"
        label="Mật khẩu mới"
        placeholder="Vui lòng nhập ít nhất 6 chữ số."
        minlength="6"
        :rules="[
          {
            required: true,
            message: 'Vui lòng nhập 6 chữ số làm mật khẩu đăng nhập của bạn.',
          },
        ]"
      />

      <!-- <van-field
        class="code"
        v-model="form.code"
        name="code"
        clearable
        label="xác nhận mã qua SMS"
        placeholder="ใส่รหัสxác nhậnทาง SMS"
        :rules="[{ required: true, message: 'ใส่รหัสxác nhậnทาง SMS' }]"
      >
        <template #button>
          <van-count-down
            :time="1000 * 60"
            format="ss s"
            v-if="isCountDownShow"
            @finish="isCountDownShow = false"
          />
          <van-button
            round
            class="send-sms-btn"
            size="small"
            type="default"
            native-type="button"
            v-else
            @click="onSendSms"
          >
            {{ registeinfo }}</van-button
          >
        </template>
      </van-field> -->
      <!-- <div class="list-phone">หมายเลขโทรศัพท์ของรหัส ที่ได้รับ. :188669855</div> -->

      <div style="margin: 16px">
        <van-button
          block
          type="danger"
          native-type="submit"
          :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
          >Gửi Thông Tin</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { sendSms, newloginPassword } from '@/api/use.js'
import { mapState } from 'vuex'
export default {
  name: '',
  components: {},
  data() {
    return {
      form: {
        password: '',
        // code: '',
        oldpass: '',
      },
      // 控制是否显示倒计时
      isCountDownShow: false,
      //  点击信息的提示
      registeinfo: 'Nhận mã xác minh SMS',
    }
  },
  mounted() {},
  created() {},
  methods: {
    // 1、点击获取短信事件
    async onSendSms() {
      this.registeinfo = 'là quyền truy cập...'
      // 调用发送验证码请求的异步请求
      if (this.form.password) {
        // 如果输入了新的密码，让倒计时显示
        this.isCountDownShow = true
        try {
          const { data } = await sendSms({
            userphone: this.userphone,
            must: 2,
          })
          data.ret === 1
            ? this.$toast('CAPTCHA đã được nhận thành công')
            : this.$toast('Đã gửi thành công, chỉ gửi một lần trong 60 giây.')
        } catch (error) {
          this.isCountDownShow = false
        }
      } else this.$toast('Vui lòng nhập số điện thoại di động hợp lệ.')
      this.registeinfo = 'Nhận mã xác minh SMS'
    },
    async onSubmit() {
      try {
        const { data } = await newloginPassword({
          newpass: this.form.password,
          oldpass: this.form.oldpass,
        })
        data.ret === 1
          ? this.$toast('Mật khẩu thanh toán đã được sửa đổi.')
          : this.$toast('Không sửa đổi được')
      } catch (error) {
        this.$toast('Vui lòng thử lại sau.')
      }
    },
  },
  computed: {
    ...mapState(['userphone']),
  },
}
</script>

<style lang="less" scoped>
.pay_password {
  font-family: 'PingFang-Regular';

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }
    /deep/ .van-nav-bar__title {
      // color: #fff;
    }
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-form {
    margin: 15px 0;
    color: #000;
    /deep/ .van-field {
      font-size: 22px;
      /deep/ .van-field__label {
        width: 265px !important;
      }
      /deep/ .van-field__body {
        input {
          text-align: center;
        }
      }
    }

    .code {
      /deep/ .van-field__label {
        width: 180px !important;
      }
      button {
        border: none;
        color: #ff0000;
        background-color: #fff;
      }
    }
    .list-phone {
      text-align: right;
      margin-top: 24px;
      margin-right: 24px;
      font-size: 24px;
      color: #666666;
    }
  }
}
</style>
